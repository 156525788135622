export const idlFactory = ({ IDL }) => {
  const Result_2 = IDL.Variant({ 'ok' : IDL.Null, 'err' : IDL.Text });
  const PubKey = IDL.Record({
    'e' : IDL.Nat,
    'n' : IDL.Nat,
    'alg' : IDL.Text,
    'kid' : IDL.Text,
    'kty' : IDL.Text,
    'use' : IDL.Text,
  });
  const Result = IDL.Variant({
    'ok' : IDL.Record({ 'keys' : IDL.Vec(PubKey) }),
    'err' : IDL.Text,
  });
  const Time = IDL.Int;
  const Delegation = IDL.Record({
    'signature' : IDL.Vec(IDL.Nat8),
    'delegation' : IDL.Record({
      'pubkey' : IDL.Vec(IDL.Nat8),
      'targets' : IDL.Opt(IDL.Vec(IDL.Principal)),
      'expiration' : IDL.Int,
    }),
  });
  const AuthResponse = IDL.Record({
    'kind' : IDL.Text,
    'delegations' : IDL.Vec(Delegation),
    'authnMethod' : IDL.Text,
    'userPublicKey' : IDL.Vec(IDL.Nat8),
  });
  const Result_1 = IDL.Variant({
    'ok' : IDL.Record({ 'auth' : AuthResponse }),
    'err' : IDL.Text,
  });
  const PrepRes = IDL.Variant({
    'ok' : IDL.Record({ 'pubKey' : IDL.Vec(IDL.Nat8), 'expireAt' : Time }),
    'err' : IDL.Text,
  });
  const HttpHeader = IDL.Record({ 'value' : IDL.Text, 'name' : IDL.Text });
  const HttpResponsePayload = IDL.Record({
    'status' : IDL.Nat,
    'body' : IDL.Vec(IDL.Nat8),
    'headers' : IDL.Vec(HttpHeader),
  });
  const TransformArgs = IDL.Record({
    'context' : IDL.Vec(IDL.Nat8),
    'response' : HttpResponsePayload,
  });
  const CanisterHttpResponsePayload = IDL.Record({
    'status' : IDL.Nat,
    'body' : IDL.Vec(IDL.Nat8),
    'headers' : IDL.Vec(HttpHeader),
  });
  const Main = IDL.Service({
    'addMod' : IDL.Func([IDL.Principal], [Result_2], []),
    'checkEmail' : IDL.Func([IDL.Principal, IDL.Text], [IDL.Bool], ['query']),
    'fetchGoogleKeys' : IDL.Func([], [Result], []),
    'getBalance' : IDL.Func(
        [],
        [IDL.Record({ 'val' : IDL.Nat, 'text' : IDL.Text })],
        ['query'],
      ),
    'getDelegation' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Vec(IDL.Nat8), Time],
        [Result_1],
        ['query'],
      ),
    'getEmail' : IDL.Func(
        [IDL.Principal, IDL.Text],
        [IDL.Opt(IDL.Text)],
        ['query'],
      ),
    'getPrincipal' : IDL.Func([], [IDL.Text], ['query']),
    'getStats' : IDL.Func([], [IDL.Vec(IDL.Text)], ['query']),
    'prepareDelegation' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Vec(IDL.Nat8), IDL.Nat],
        [PrepRes],
        [],
      ),
    'setGoogleKeys' : IDL.Func([IDL.Text], [Result], []),
    'transform' : IDL.Func(
        [TransformArgs],
        [CanisterHttpResponsePayload],
        ['query'],
      ),
  });
  return Main;
};
export const init = ({ IDL }) => { return []; };
